var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","clipped-left":"","clipped-right":"","color":"secondary","dark":""}},[_c('v-toolbar-title',{staticClass:"align-center d-flex"},[_c('span',{staticClass:"logo-icon"},[_c('v-img',{staticClass:"ml-0",attrs:{"src":require("@/assets/images/logo-branca-main.png"),"max-height":"60px","max-width":"200px","contain":""},on:{"click":_vm.goHome}})],1)]),_c('div',{on:{"click":_vm.showhideLogo}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.$vuetify.breakpoint.smAndDown
          ? _vm.setSidebarDrawer(!_vm.Sidebar_drawer)
          : _vm.$emit('input', !_vm.value)}}})],1),(_vm.showSearch)?_c('div',{staticClass:"searchinput primary"},[[_c('v-text-field',{staticClass:"mt-3 mb-0",attrs:{"placeholder":"Search & hit enter","append-icon":"mdi-close"},on:{"click:append":_vm.searchbox}})]],2):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"text-caption d-none d-sm-flex empresa text-center"},[_vm._v(" "+_vm._s(this.empresaSelecionada.tenant)+" ")]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition","max-height":(_vm.$vuetify.breakpoint.height * 8) / 10},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"dark":"","icon":""}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-account-box-multiple")])],1)]}}])},[_c('v-list',[_c('h4',{staticClass:"px-5 py-3 pt-2 font-weight-medium title"},[_vm._v(" Perfis da Empresa ")]),_c('v-divider'),_vm._l((_vm.perfisAExibir),function(perfil){return _c('v-list-item',{key:perfil.cod,on:{"click":function($event){return _vm.handleSetPerfilAtual(perfil.cod)}}},[_c('v-list-item-title',[_c('div',{staticClass:"d-flex align-center py-3"},[(perfil.cod === _vm.perfilAtual)?_c('div',{},[_c('v-btn',{staticClass:"mr-3",attrs:{"depressed":"","fab":"","x-small":"","dark":"","color":"#00aa9e"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-check")])],1)],1):_vm._e(),_c('div',[_c('h4',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(perfil.nome))])])])])],1)})],2)],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition","max-height":(_vm.$vuetify.breakpoint.height * 8) / 10},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"dark":"","icon":""}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-swap-horizontal")])],1)]}}])},[_c('v-list',[_c('h4',{staticClass:"px-5 py-3 pt-2 font-weight-medium title"},[_vm._v("Empresas")]),_c('v-divider'),_vm._l((_vm.user.tenants),function(tenant,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.handleSetEmpresaAtual(tenant)}}},[_c('v-list-item-title',[_c('div',{staticClass:"d-flex align-center py-3"},[(_vm.isEmpresaSelecionada(tenant))?_c('div',{},[_c('v-btn',{staticClass:"mr-3",attrs:{"depressed":"","fab":"","x-small":"","dark":"","color":"#00aa9e"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-check")])],1)],1):_vm._e(),_c('div',[_c('h4',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(tenant.tenant))]),_c('small',{staticClass:"text--secondary"},[_vm._v(_vm._s(tenant.cnpj))])])])])],1)})],2)],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"dark":"","icon":""}},on),[_c('v-avatar',{attrs:{"size":"40"}},[_c('v-icon',[_vm._v(" mdi-account ")])],1)],1)]}}])},[_c('v-list',_vm._l((_vm.userprofile),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.exec(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }