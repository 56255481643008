<template>
  <v-app-bar app clipped-left clipped-right color="secondary" dark>
    <!---Logo part -->
    <v-toolbar-title class="align-center d-flex">
      <span class="logo-icon">
        <v-img
          class="ml-0"
          src="@/assets/images/logo-branca-main.png"
          max-height="60px"
          max-width="200px"
          contain
          @click="goHome"
        />
      </span>
    </v-toolbar-title>
    <!---Logo part -->
    <!---/Toggle sidebar part -->
    <div @click="showhideLogo">
      <v-app-bar-nav-icon
        @click="
          $vuetify.breakpoint.smAndDown
            ? setSidebarDrawer(!Sidebar_drawer)
            : $emit('input', !value)
        "
      />
    </div>
    <!---/Toggle sidebar part -->
    <!---Search part -->
    <!-- <v-btn dark icon class="mr-1 d-sm-block d-none" @click="searchbox">
      <v-icon>mdi-magnify</v-icon>
    </v-btn> -->

    <div v-if="showSearch" class="searchinput primary">
      <template>
        <v-text-field
          placeholder="Search & hit enter"
          class="mt-3 mb-0"
          append-icon="mdi-close"
          @click:append="searchbox"
        ></v-text-field>
      </template>
    </div>
    <!---/Search part -->
    <v-spacer />
    <!---right part -->
    <div class="text-caption d-none d-sm-flex empresa text-center">
      {{ this.empresaSelecionada.tenant }}
    </div>
    <v-spacer />
    <!-- perfis da empresa atual -->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      :max-height="($vuetify.breakpoint.height * 8) / 10"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-icon medium>mdi-account-box-multiple</v-icon>
        </v-btn>
      </template>

      <v-list>
        <h4 class="px-5 py-3 pt-2 font-weight-medium title">
          Perfis da Empresa
        </h4>
        <v-divider></v-divider>
        <v-list-item
          v-for="perfil in perfisAExibir"
          :key="perfil.cod"
          @click="handleSetPerfilAtual(perfil.cod)"
        >
          <v-list-item-title>
            <div class="d-flex align-center py-3">
              <div class v-if="perfil.cod === perfilAtual">
                <v-btn class="mr-3" depressed fab x-small dark color="#00aa9e">
                  <v-icon dark>mdi-check</v-icon>
                </v-btn>
              </div>
              <div>
                <h4 class="font-weight-medium">{{ perfil.nome }}</h4>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!---Empresas do usuario -->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      :max-height="($vuetify.breakpoint.height * 8) / 10"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-icon large>mdi-swap-horizontal</v-icon>
        </v-btn>
      </template>

      <v-list>
        <h4 class="px-5 py-3 pt-2 font-weight-medium title">Empresas</h4>
        <v-divider></v-divider>
        <v-list-item
          v-for="(tenant, i) in user.tenants"
          :key="i"
          @click="handleSetEmpresaAtual(tenant)"
        >
          <v-list-item-title>
            <div class="d-flex align-center py-3">
              <div class v-if="isEmpresaSelecionada(tenant)">
                <v-btn class="mr-3" depressed fab x-small dark color="#00aa9e">
                  <v-icon dark>mdi-check</v-icon>
                </v-btn>
              </div>
              <div>
                <h4 class="font-weight-medium">{{ tenant.tenant }}</h4>
                <small class="text--secondary">{{ tenant.cnpj }}</small>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-avatar size="40">
            <v-icon> mdi-account </v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in userprofile"
          :key="i"
          @click="exec(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Perfil from '@/utils/perfil'
// import gatesApi from '@/services/tenants/gates'
import getNewToken from '@/message/get_new_token'

export default {
  name: 'Header',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showLogo: true,
    showSearch: false,
    interval: null,
    intervaloEmSegundos: 60,
    notifications: [
      {
        title: 'Launch Admin',
        iconbg: 'error',
        icon: 'mdi-link-variant',
        desc: 'Just see the my new admin!',
        time: '9:30AM',
      },
      {
        title: 'Event today',
        iconbg: 'success',
        icon: 'mdi-calendar-check',
        desc: 'Just a reminder that you have event',
        time: '10:30AM',
      },
      {
        title: 'Settings',
        iconbg: 'info',
        icon: 'mdi-cog',
        desc: 'You can customize this template as you want',
        time: '11:30AM',
      },
      {
        title: 'Pavan Kumar',
        iconbg: 'indigo',
        icon: 'mdi-account',
        desc: 'Sent you an notification',
        time: '12:30AM',
      },
    ],
    messages: [
      {
        title: 'Sonu Nigam',
        avatar: '1',
        avatarstatus: 'success',
        desc: 'Singing Show tonigh at 9pm!',
        time: '9:30AM',
      },
      {
        title: 'Sonu Nigam',
        avatar: '2',
        avatarstatus: 'error',
        desc: 'The greate malody songs ever sung',
        time: '10:30AM',
      },
      {
        title: 'Arijit singh',
        avatar: '3',
        avatarstatus: 'warning',
        desc: 'You can customize this template as you want',
        time: '11:30AM',
      },
      {
        title: 'Pavan Kumar',
        avatar: '4',
        avatarstatus: 'success',
        desc: 'Sent you an notification',
        time: '12:30AM',
      },
    ],
    userprofile: [
      { title: 'Meu perfil', method: 'perfil()' },
      // { title: 'My Balance', method: '#' },
      // { title: 'Inbox', method: '#' },
      // { title: 'Account Setting', method: '#' },
      { title: 'Sair', method: 'logout()' },
    ],
    href() {
      return undefined
    },
    empresaSelecionada: {},
  }),

  created() {
    this.empresaSelecionada = this.empresaAtual
  },

  computed: {
    ...mapState(['Sidebar_drawer']),
    ...mapState('auth', ['user', 'empresaAtual', 'perfilAtual']),
    perfisEmpresaAtual() {
      return Object.entries(Perfil)
        .map(([nome, cod]) => ({
          nome,
          cod,
        }))
        .filter((i) => this.empresaSelecionada.tiposContrato.includes(i.cod))
    },
    perfisAExibir() {
      return this.perfisEmpresaAtual.filter(
        (p) =>
          Array.isArray(this.empresaAtual.perfisColaborador) &&
          this.empresaAtual.perfisColaborador.includes(p.cod)
      )
    },
  },

  methods: {
    ...mapActions('auth', ['setEmpresaAtual', 'setPerfilAtual']),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    ...mapMutations({
      setSidebarDrawer: 'SET_SIDEBAR_DRAWER',
    }),
    goHome() {
      this.$router.push({ path: '/' })
    },
    showhideLogo: function () {
      this.showLogo = !this.showLogo
    },
    searchbox: function () {
      this.showSearch = !this.showSearch
    },
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.push({ path: '/auth/login' })
    },
    // async getSolicitacoesEmAberto(){
    //   const retorno = await gatesApi.getSolicitacaoEmAberto(this.empresaAtual)
    //   if(retorno.total_caminhoes > 0){
    //     this.errorMessage("" + retorno.total_caminhoes + " novo(s) caminhões chamados!")
    //   }
    // },
    perfil() {
      this.$router.push({ path: '/gerencial/perfil' })
    },
    exec(item) {
      eval(`this.${item.method}`)
    },
    handleSetEmpresaAtual(empresa) {
      this.empresaSelecionada = empresa
      getNewToken(empresa)
      this.setEmpresaAtual(empresa)
    },
    handleSetPerfilAtual(perfil) {
      this.setPerfilAtual({
        empresaAtual: this.empresaSelecionada,
        perfil,
      })
    },
    isEmpresaSelecionada(empresa) {
      return empresa.public_id === this.empresaSelecionada.public_id
    },
  },
}
</script>

<style lang="scss">
.empresa {
  @media screen and (max-width: 674px) {
    width: 25%;
  }
}

.v-application #main-sidebar.theme--dark.white {
  background: #363636 !important;
}
.hidelogo {
  display: none;
}
.searchinput {
  position: absolute;
  width: 100%;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 0 15px;
}
.descpart {
  max-width: 220px;
}
</style>
