<template>
  <div>
    <v-dialog v-model="dialog" v-if="plantao" max-width="720">
      <v-card>
        <v-card-title class="text-h5">
          Ops! Não temos atendentes nesse momento.
        </v-card-title>

        <v-card-text>
          <p>
            Por favor, entre em contato com o nosso plantonista:
            <br />
            {{ plantao.plantonista.nome }}:
            <b> {{ plantao.plantonista.celular }}</b>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Plantao',
  data() {
    return {
      dialog: false,
      plantao: null,
    }
  },
  computed: {
    href() {
      if (!this.plantao) {
        return '#'
      }
      return `https://wa.me//55${this.plantao.plantonista.celular}?text=Olá%20${this.plantao.plantonista.nome}`
    },
  },
  methods: {
    open(plantao) {
      this.dialog = true
      this.plantao = plantao
    },
    close() {
      this.plantao = null
      this.dialog = false
    },
  },
}
</script>
<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  .v-navigation-drawer__border {
    display: none;
  }
  .v-list {
    padding: 8px 15px;
  }
  .v-list-item {
    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }
  .profile-bg {
    background: url('../../../assets/images/user-info.jpg') no-repeat;
    .v-avatar {
      padding: 45px 0;
    }
  }
}
</style>
