<template>
  <v-app id="inspire">
    <Header v-model="expandOnHover"></Header>

    <v-main>
      <v-container fluid class="page-wrapper">
        <router-view />
      </v-container>
      <Message />
      <Plantao ref="plantao" />
    </v-main>
    <Sidebar
      :expand-on-hover.sync="expandOnHover"
      @open_plantao="open_plantao"
    ></Sidebar>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from './header/Header'
import Sidebar from './sidebar/Sidebar'
import Footer from './footer/Footer'
import Message from './message/Message'
import Plantao from './plantao/Plantao'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'Layout',

  components: {
    Header,
    Sidebar,
    Footer,
    Message,
    Plantao,
  },

  props: {
    source: String,
  },
  data: () => ({
    expandOnHover: true,
  }),
  computed: {
    ...mapState(['Customizer_drawer']),
  },

  methods: {
    ...mapMutations({
      setCustomizerDrawer: 'SET_CUSTOMIZER_DRAWER',
    }),
    open_plantao(plantao) {
      this.$refs['plantao'].open(plantao)
    },
  },
}
</script>

<style></style>
