<template>
  <v-footer app clipped-left clipped-right color="secondary" dark>
    <v-col cols="12 d-flex justify-space-between align-center">
      <span>© {{ new Date().getFullYear() }} — Rota Certa Sistemas</span>
      <span>Versão 2.0.5</span>
    </v-col>
  </v-footer>
</template>
<script>
export default {
  name: 'Footer',
  data: () => ({}),
}
</script>
<style lang="scss"></style>
